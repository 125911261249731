
/*-----------------------------------------------------------------------------------

	Shortcodes: faqs.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Toggle - FAQs
-----------------------------------------------------------------*/

.faqs {
	.toggle {
//		border-bottom: 1px solid #EEE;
//		padding-bottom: 12px;
		margin-bottom: 12px;
		.togglet,
		.toggleta {
			font-size: $font-size-base;
			color: $body-color;
			padding-left: 50px;
			padding-top: 3px;
		}
		.toggleta {
			color: $blue;
		}
		.togglec {
			padding-bottom: 10px;
			padding-left: 50px;
		}
	}
	i {
		top: 1px;
		font-size: 14px;
	}
}


/* FAQ List
-----------------------------------------------------------------*/

.faqlist {
	li {
		margin-bottom: 5px;
		font-size: $font-size-base;
		a {
			color: #444;
			&:hover {
				color: $theme-color !important;
			}
		}
	}
}

.pwh-icon-plus-gray {
	content: url('/img/icon-plus-gray.svg');
}
.pwh-icon-minus-toggle-blue {
	content: url('/img/icon-minus-toggle-blue.svg');
}
