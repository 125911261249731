
/* ----------------------------------------------------------------

	footer.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/

#footer {
	// position: relative;
	text-align: center;
	//background-color: #EEE;
	//border-top: 5px solid rgba(0,0,0,0.2);
//	.footer-widgets-wrap {
// 		position: relative;
// 		padding: 80px 0;
//	}
}

.device-lg,
.device-md {
	&.sticky-footer {
		#top-bar { z-index: 299; }
		#header { z-index: 199; }
		#slider,
		#page-submenu,
		#page-title,
		#content,
		#slider,
		#page-submenu,
		#page-title,
		#content { z-index: 2; }
		#footer {
			position: fixed;
			top: auto;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}
		&:not(.stretched) #footer {
			left: 50%;
			margin-left: -610px;
			width: 1220px;
		}
	}
}

.device-md.sticky-footer:not(.stretched) #footer {
	margin-left: -500px;
	width: 1000px;
}

.footer-widgets-wrap {
	.col_full,
	.col_half,
	.col_one_third,
	.col_two_third,
	.col_three_fourth,
	.col_one_fourth,
	.col_one_fifth,
	.col_two_fifth,
	.col_three_fifth,
	.col_four_fifth,
	.col_one_sixth,
	.col_five_sixth {
		margin-bottom: 0;
	}
}

.external-app-links {
	font-weight: bold;
	a:link, a:active, a:visited {
		padding-bottom: 10px;
		margin: 0px 15px 2px 15px;
		color: $navy;
		border-bottom: 3px solid $white;
		&:hover {
			border-bottom: 3px solid $blue !important;
			color: $navy !important;
		}	
	}
}

.copyright-links {
	color: $gray1;
	a {
		display: inline-block;
		color: $gray1;
		margin: 0 3px;
		border-bottom: none;
		&:hover {
			color: $blue;
		}	
	}
	strong {
		font-weight: bold;
		color: $navy;
		a {
			display: inline-block;
			margin: 0 3px;
			color: $navy;
			border-bottom: none;
			&:hover {
				color: $blue;
			}	
		}
	}
}

.copyrights-menu {
	margin-bottom: 10px;
	a {
		font-size: $font-size-base;
		margin: 0 10px;
		border-bottom: 0 !important;
	}
}

.footer-logo {
	display: block;
	margin-bottom: 30px;
}

.social-footer {
	display: inline;
	a {
		cursor: pointer;
		&:hover {
			img {
				opacity: .6;
			}
		}
	}
	img {
		height: 50px;
		width: 50px;
		margin: 40px 6px 70px 6px;
	}
}
.bapple {
		font-size: x-small;
		color: $blue;
		a {
		color: $blue;
		border-bottom: 2px solid $blue !important;
		&:hover {
			color: $blue-hover;
			border-bottom: 2px solid $blue-hover !important;
		}
	}
}
body.device-sm #hide-on-sm {
	display: none!important;
}
