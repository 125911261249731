
/* ----------------------------------------------------------------

	Layouts.scss

-----------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/

.section {
	position: relative;
	margin: $section-margin 0;
	padding: $section-padding 0;
	background-color: $section-bg;
	overflow: hidden;

	.container {
		z-index: 2;
	}

	.container + .video-wrap {
		z-index: 1;
	}
}

.parallax {
	background-color: transparent;
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
	overflow: hidden;
}

.mobile-parallax,
.video-placeholder {
	background-size: cover !important;
	background-attachment: scroll !important;
	background-position: center center;
}

.revealer-image {
	position: relative;
	bottom: -($section-padding * 2);
	@include transition(bottom .3s ease-in-out);
}

.section:hover .revealer-image { bottom: -$section-padding; }


/* ----------------------------------------------------------------
	Columns & Grids
-----------------------------------------------------------------*/


.postcontent {
	width: $postcontent;

	&.bothsidebar {
		width: $bothsidebar;
	}
}

// .sidebar { width: $sidebar; }
.col_full { width: 100%; }
.col_half { width: 48%; }
.col_one_third { width: 30.63%; }
.col_two_third { width: 65.33%; }
.col_one_fourth { width: 22%; }
.col_three_fourth { width: 74%; }
.col_one_fifth { width: 16.8%; }
.col_two_fifth { width: 37.6%; }
.col_three_fifth { width: 58.4%; }
.col_four_fifth { width: 79.2%; }
.col_one_sixth { width: 13.33%; }
.col_five_sixth { width: 82.67%; }

body.stretched .container-fullwidth {
	position: relative;
	padding: 0 60px;
	width: 100%;
}

.postcontent,
.sidebar,
.col_full,
.col_half,
.col_one_third,
.col_two_third,
.col_three_fourth,
.col_one_fourth,
.col_one_fifth,
.col_two_fifth,
.col_three_fifth,
.col_four_fifth,
.col_one_sixth,
.col_five_sixth {
	display: block;
	position: relative;
	margin-right: 4%;
	margin-bottom: $margin-base;
	float: left;
}

.postcontent,
.sidebar { margin-right: $postcontent-margin; }

.col_full {
	clear: both;
	float: none;
	margin-right: 0;
}

.postcontent.col_last,
.sidebar.col_last { float: right; }

.col_last {
	margin-right: 0 !important;
	clear: right;
}

/* ----------------------------------------------------------------
	PWH customisation CSS
-----------------------------------------------------------------*/

.sidebar {
	background-color: $gray5;
	margin: 0px;
	padding: 5%;
	h3 {
		color: $navy;
		font-size: $font-size-h2;
		font-family: $secondary-font;
		text-align: right;
	}
	p {
		margin-bottom: 15px;
	}
}
.pwh-img-fullwidth {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	clear: both;
	img {
		width: 100vw !important;
	}
}

.img-centre {
	position: relative;
	clear: both;
	img {
		width: 100% !important;
	}
}
.featured-image {
	background-repeat: no-repeat !important;
	background-position: center top !important;
	background-size:cover !important;
	height: 550px;
	text-align: right;
	vertical-align: middle;
	padding-right: 35px;
	padding-top: 35px;
}
@media screen and (max-width: 550px) {
	.featured-image {
		height: 100vw !important;
	}
	.featuredImageCaption {
		display: inline-flex !important;
		margin-top: calc(100vw - 113px) !important;
		width: 100vw !important;
		z-index: 2;
    	position: relative;
	}
}
.generalImageCaption {
	// text-transform: lowercase;
    background-color: $navy;
    color: $white;
	padding: 20px 30px 20px 30px;
	font-family: $secondary-font;
    font-size: $font-size-h2;
	font-weight: lighter;
	position: absolute;
	right: 20px;
	bottom: 20px;
}

#pwh-portal-nav {
	margin: 35px 5px 0px 5px;
}

@media only screen and (max-width: 767px) {
	.generalImageCaption {
		padding: 5px 12px;
		font-size: 20px;
		right: 10px;
		bottom: 10px;
	}
	#pwh-portal-nav {
		margin: unset;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
.sidebar {width: 100% !important;}
}

#pwh-portal-nav li {
    background-color: $gray3;
    margin-left: 1.5px;
	margin-right: 1.5px;
	margin-top: 3px;
	font-size: $font-size-h6;
	color: $notblack;
	font-weight: bold;
	vertical-align: middle;
	a {
		background-color: $gray3;
		color: $notblack;
		width: 100%;
		text-align: center;
		padding: 25px 15px 0 15px;
		height: 65px;
		&:hover {
			color: $white;
			background-color: $navy;
		}
	}
}

// .in#pwh-portal-nav li {
//  a{
// 	width: 100%;
//  }
// }

.navbar-brand {
	float: right;
	font-size: $font-size-h6;
	color: $notblack;
	font-weight: bold;
	color: $blue;
	a {
		color: $blue;
		font-weight: bold;
		font-size: $font-size-h6;
		&:hover {
			color: $white;
		}
	}
}


/* ----------------------------------------------------------------
	Homepage layout elements (apart from slider in sliders.scss
-----------------------------------------------------------------*/

.padding-fix{
 padding-left: 5px;
 padding-right: 5px;
}

.call-out-block {
	margin: 10px 0px 10px 0px;
	height: 250px;
	-webkit-transition: all 3s ease-in-out;
	transition: all 3s ease-in-out;
	background-size: 120% !important;
	background-position: center;
	background-repeat: no-repeat !important;
	position: relative;
	&:hover {
		background-size: 130% !important;
	}
}

.call-out-block-blue{
	background-color:rgba(8, 51, 120, 0.3);
	padding: 100px 15px 0px 15px;
	-webkit-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;
    width: 100%;
	height: 100%;
	img {
		margin-top: 15px;
		position: absolute;
		top: 50%;
		left: 50%;
	}
	span {
		line-height: $font-size-base;
		color: $white;
		width: 70%;
		font-size: 26px;
		font-family: $secondary-font;
		span {
			font-family: $body-font; font-size: $font-size-base; display: block;
		}
	}
	span a {
		font-weight: 400;
		color: $blue;
		font-size: 20px;
	}
	span a:hover {
		color: $blue-hover;
	}
}
.call-out-block-blue:hover {
	background-color: rgba(8, 51, 120, 0.2);
}
.search-results {
	background-color: $gray5;
	padding: 5%;
	text-align: left;
	h3 {
		margin: 0;
	}
	cite {
		color: $gray1;
		font-style: normal;
		position: relative;
		left: 10px;
		font-size: 14px;
		font-style: normal;
	}
	ul li {
		padding-bottom: 10px !important;
	}
}
.search-results ul li:before {
	content: none  !important;

}
.news-container{
	padding-left: 5px;
	padding-right: 5px;
	overflow: hidden;
}
.news-homepage, .blog-homepage, .motd-homepage {
	padding: 35px 50px 0 50px;
	.newsdate {
		font-size: $footer-size;
		color: $testimonialblack;
		height: 40px;
		display: block;
	}
	div {
		text-align: center;
	}
	.newsbg {
			height: 20vw;
			width: 20vw;
			background-repeat: no-repeat !important;
			background-position: top center !important;
			background-size: cover !important;
			display: inline-block;
			margin: 0 0 25px 0;
	}
	h3 {
		font-family: $secondary-font;
		font-size: $font-size-h2;
		color: $navy;
		font-weight: 400;
	}
	a {
		color: $blue;
		font-size: 20px;
		border-bottom: 2px solid $blue !important;
		font-family: $secondary-font;
		&:hover {
			color: $blue-hover;
			border-bottom: 2px solid $blue-hover !important;
		}
	}
}
.motd-homepage {
	padding: 35px 50px 0 50px;
	background-color: $gray5;
	margin-bottom: -1000px;
    padding-bottom: 1000px;
}

.ThumbGrid {
	max-width: 800px;
}

.container h2 {
    margin-top: 35px;
}

.news-entry-pagination {
	.newsdate {
		font-size: $footer-size;
		color: $testimonialblack;
		height: 40px;
		display: block;
	}
	div {
		text-align: center;
	}
	.newsbg {
			height: 20vw;
			width: 20vw;
			background-repeat: no-repeat !important;
			background-position: top center !important;
			background-size: cover !important;
			display: inline-block;
			margin: 0 0 25px 0;
	}
	h3 {
		font-family: $secondary-font;
		font-size: $font-size-h2;
		color: $navy;
		font-weight: 400;
	}
	a {
		color: $blue;
		font-size: 20px;
		border-bottom: 2px solid $blue !important;
		font-family: $secondary-font;
		&:hover {
			color: $blue-hover;
			border-bottom: 2px solid $blue-hover !important;
		}
	}
}

.news-ls {
	padding-top: 15px;
	h3 {
		font-family: $secondary-font;
		font-weight: 400;
		font-size: $font-size-h2;
	}
	span {
		font-size: $footer-size;
		color: $testimonialblack;
		height: 30px;
		display: block;
	}
}

.white {
	color: $white;
	h2 {
		font-family: $body-font;
		font-size: 40px;
		color: $white;
	}
	span {
		font-size: 14px;
	}
	h3 {
		font-family: $secondary-font;
		font-size: 36px;
		color: $white;
		line-height: 1.1;
	}
	a {
		color: $white;
		border-bottom: 2px solid $white !important;
		&:hover {
			color: $blue-hover;
			border-bottom: 2px solid $blue-hover !important;
		}
	}
}