
/*-----------------------------------------------------------------------------------
	typography.scss
-----------------------------------------------------------------------------------*/


dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}
#content ul {
	list-style: none;
	padding: 0;
	// margin: 0;
	margin-left: 15px;
}
#content ul ul {
	margin-top: 15px;
}
/* Custom coloured ordered and unorded lists items */
#content li::before {
	content: "• ";
	color: $red;
	margin-right: 15px;
}
ol {list-style: none; counter-reset: li}

#content ol li::before {
	content: "."counter(li);
	color: $red;
	display: inline-block;
	text-align: right;
	direction: rtl;
	margin-left: 15px;
}

li {counter-increment: li}

#content li { padding-bottom: 15px;}

#content ul li p, #content ol li p {
	display: inline;
} 

/* Fix for flex slider */
#content .fslider ol li::before, #content .fslider ul li::before, {
	content: none;
	color: unset;
	display: unset;
	text-align: unset;
	direction: unset;
	margin: 0px;
}

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow:hidden;
}

::selection {
	background: $theme-color;
	color: #FFF;
	text-shadow: none;
}

::-moz-selection {
	background: $theme-color; /* Firefox */
	color: #FFF;
	text-shadow: none;
}

::-webkit-selection {
	background: $theme-color; /* Safari */
	color: #FFF;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }


/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


body {
	line-height: $line-height-base;
	color: $body-color;
	font-size: $font-size-base;
	font-family: $body-font;
}

a {
	text-decoration: none !important;
	color: $theme-color;
	&:hover {
		color: #222;
	}
	img {
		border: none;
	}
}

img { max-width: 100%; }
iframe { border: none !important; }


/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $heading-color;
	font-weight: 400;
	line-height: $line-height-base;
	margin: 0 0 30px 0;
	font-family: $heading-font;
}
// added for PWH heading needing callout words and lowercase page titles
// h1 {text-transform: lowercase;}
h1 strong {color: $blue; font-weight: 400;}


h5,
h6 { margin-bottom: 20px; }

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

h4 { font-weight: 600; }

h5,
h6 { font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6 {
	> span:not(.nocolor) {
		color: $theme-color;
	}
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: 30px; }

small { font-family: $body-font; }

#wrapper {
	position: relative;
	float: none;
	width: 1220px;
	margin: 0 auto;
	background-color: #FFF;
	@include box-shadow(0 0 10px rgba(0,0,0,0.1));
}

// PWH Span caption box

.navy-caption-right { 
	float: right;
	padding: 15px;
	background-color: $navy; 
	color: $white; 
	font-family: $secondary-font;
	text-align: center;
	font-size: ( $font-size-base + 2 );
	font-size: $font-size-h3;
}

//needs work still
img.img-fullwidth
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

// #qanda li::before {
// 	content: " ";
// 	color: $white; /* or whatever color you prefer */
// 	margin-right: none;
// }


span.featuredImageCaption { 
	display: inline-block;
	//text-transform: lowercase;
	margin-top: 400px; 
	background-color: $navy; 
	font-family: $secondary-font; 
	color: $white; 
	padding: 20px 30px 20px 30px;
	font-size: $font-size-h2; 
	font-weight: lighter; 
}

// PWH Customisations
h2 {
	font-family: $secondary-font;
}

.portallink {
	text-transform: none;
	border: solid 2px $navy !important;
	color: $navy !important;
	background-color: $white;
	background: $white;
    text-align: center;
	padding: 15px 30px;
	a {
		color: $white;
		border: solid 2px $navy !important;
	}
	&:hover {
		border: solid 2px $blue !important;
		color: $white !important;
		background-color: $blue;
		background: $blue;
		transition: all .8s ease;
		-webkit-transition: all .8s ease;
	}
}
button {
    border-radius: 0px;
}

