/* ----------------------------------------------------------------

	formbuilder.scss

-----------------------------------------------------------------*/

.formbuilder2 {
    background-color: $gray5;
    color: $notblack;
    font-family: $body-font;
    font-weight: 400;
    padding: 116px 118px 100px 118px;
    .heading {
        margin-top: 35px;
    }
    button {
        img {
            height: 30px;
            width: 30px;
            margin-right: 20px;
        }
        font-weight: 400;
        width: 200px;
        height: 60px;
        border: none;
        color: $white;
        background-color: $navy;
    }
    button:hover {
        background-color: $blue;
        transition: all .8s ease;
		-webkit-transition: all .8s ease;
    }
    fieldset {
        legend {
            display: block;
            font-family: $secondary-font;
            width: 100%;
            font-size: $font-size-h2;
            line-height: inherit;
            color: $navy;
            border-bottom: unset;
            margin-bottom: 0px;
        }
        label {
            display: initial;
            text-transform: unset;
            font-size: $formbuilder-labels;
            font-weight: unset;
            font-family: $body-font;
            letter-spacing: unset;
            color: $notblack;
            cursor: unset;
        }
        input {
            display: inline;
            vertical-align: middle;
            text-align: center;
            padding: 5px;
            font-size: $formbuilder-input;
            line-height: 1.42857143;
            color: $notblack;
            background-color: $gray5;
            background-image: none;
            border: none;
            border-bottom: solid 2px $gray2;
            border-radius: 0!important;
            transition: border-color .15s ease-in-out;
        }
        input[type="radio"] {
            margin-bottom: 5px;
        }
        input[type="checkbox"] {
            margin-bottom: 5px;
        }
        input[type="select"] {
        border: none;
        border-bottom: solid 2px $gray2;
        background: none;
        }
        // input:invalid {
        //      color: $red;
        //         input::after{
        //             content: "*";
        //             color: $red;
        //         }
        // }
        textarea {
            margin-bottom: 55px;
            font-size: $formbuilder-input;
            line-height: 1.42857143;
            color: $notblack;
            background-color: $gray5;
            background-image: none;
            border: none;
            height: 150px;
            border-bottom: solid 2px $gray2;
        }
    }
}

@media only screen and (max-width: 768px) {
    .formbuilder2 {
        padding: 5%;
    }
}

.notifications {
    color: $notblack;
}

.errors, .error-message {
    color: $red;
}
label.required:after {
    color: $red;
    content: ' *';
    display:inline;
}