/* ----------------------------------------------------------------

	twinschool.scss

-----------------------------------------------------------------*/

.billtest {
    color: greenyellow;
    background-color: black;
}

.twin-round {
    @include border-radius(50%);
}
// .twin-gray {
//     background-image: url('/img/twin/middle-blue-line.svg');
//     position:absolute;
//     background-size: cover;
// }
#twin-buttons li {
        list-style: none;
}

@media (max-width: 992px) {
    .twin-wrap {
        background-image: none !important;
    }
    #twin-mark {
        width: unset;
        height: unset;
    }
    #twin-mark-div {
        margin-left: 0 !important;
    }
    #twin-lorna-div {
        margin-left: 0 !important;
    }
    #twin-leaders {
        width: unset;
        height: unset;
    }
}
.twin-heading {
    color: $navy;
    font-size: 32px;
    font-family: $secondary-font;
}
.twin-name {
    color: $navy;
    font-weight: 600;
    font-size: $formbuilder-labels;
}
.twin-conclusion {
    color: $navy;
    font-family: $secondary-font;
    font-size: $google-map-overlay-h2;
}

