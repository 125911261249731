
/* ----------------------------------------------------------------

	content.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/

#content {
	position: relative;
	overflow: hidden;
	background-color: #FFF;
	a {
		color: $blue;
		border-bottom: 2px solid $blue;
	}
	a:hover {
		color: $blue-hover;
		border-bottom: 2px solid $blue-hover;
	}

	p { line-height: $line-height-content; }

	.content-wrap {
		position: relative;
		padding: 80px 0;
	}

	.container { position: relative; max-width: 1600px; }
}
/* --- mobile fixes -- */

body:not(.device-touch) .grid-container { @include transition(height .4s linear); }

#content table {
	border: 2px solid $gray2;
	width: 100%;
	padding: 15px;
}
#content th, #content td {
	border: 2px solid $gray2;
	padding: 24px;
	vertical-align: top;
	text-align: left;
}

#content th {
	color: $blue;
	font-weight: bold;
	padding-top: 14px;
	padding-bottom: 14px;
	padding-left: 24px;
}

@media (max-width: 479px) {
	#content .container {
			width: unset !important;
	}
	#content th, #content td {
		font-size: 11px;
		padding: 5px;
	}
}