@font-face {
	font-family: 'font-icons-snapchat';
	src:url('fonts/font-icons-snapchat.eot?lp15pn');
	src:url('fonts/font-icons-snapchat.eot?lp15pn#iefix') format('embedded-opentype'),
		url('fonts/font-icons-snapchat.ttf?lp15pn') format('truetype'),
		url('fonts/font-icons-snapchat.woff?lp15pn') format('woff'),
		url('fonts/font-icons-snapchat.svg?lp15pn#font-icons-snapchat') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-snapchat-"], [class*=" icon-snapchat-"] { font-family: 'font-icons-snapchat' !important; }

.icon-snapchat:before {
	content: "\f2ab";
}
.icon-snapchat-ghost:before {
	content: "\f2ac";
}
.icon-snapchat-square:before {
	content: "\f2ad";
}

