.fc-calendar-container {
	position: relative;
	width: 100%;
	height: auto;
}

.fc-calendar {
	width: 100%;
	height: 100%;
}

.fc-calendar .fc-head {
	background: #ccc;
	color: #fff;
}

.fc-calendar .fc-body {
	position: relative;
	width: 100%;
	height: 100%;
	height: -moz-calc(100% - 30px);
	height: -webkit-calc(100% - 30px);
	height: calc(100% - 30px);
	border: 1px solid #ddd;
}

.fc-calendar .fc-row {
	width: 100%;
	border-bottom: 1px solid #ddd;
}

.fc-four-rows .fc-row  {
	height: 25%;
}

.fc-five-rows .fc-row  {
	height: 20%;
}

.fc-six-rows .fc-row {
	height: 16.66%;
	height: -moz-calc(100%/6);
	height: -webkit-calc(100%/6);
	height: calc(100%/6);
}

.fc-calendar .fc-row > div,
.fc-calendar .fc-head > div {
	display: inline-block;
	height: 100%;
	width:  14.28%; /* 100% / 7 */
	width: -moz-calc(100%/7);
	width: -webkit-calc(100%/7);
	width: calc(100%/7);
	position: relative;
}

/* IE 9 is rounding up the calc it seems */
.ie9 .fc-calendar .fc-row > div,
.ie9 .fc-calendar .fc-head > div {
	width:  14.2%;
}

.fc-calendar .fc-row > div {
	border-right: 1px solid #ddd;
	padding: 4px;
	overflow: hidden;
	position: relative;
}

.fc-calendar .fc-head > div {
	text-align: center;
}

.fc-calendar .fc-row > div > span.fc-date {
	width: 30px;
	height: 20px;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	color: #ddd;
	text-shadow: 0 -1px 0 rgba(255,255,255,0.8);
	bottom: 5px;
	right: 5px;
	text-align: right;
}

.fc-calendar .fc-row > div > span.fc-weekday {
	padding-left: 5px;
	display: none;
}

.fc-calendar .fc-row > div.fc-today {
	background: #fff4c3;
}

.fc-calendar .fc-row > div.fc-out {
	opacity: 0.6;
}

.fc-calendar .fc-row > div:last-child,
.fc-calendar .fc-head > div:last-child {
	border-right: none;
}

.fc-calendar .fc-row:last-child {
	border-bottom: none;
}

/* Custom Events Calendar
-----------------------------------------------------------------*/

.events-calendar {
	position: relative;
	width: 100%;
	height: auto;
}

.fc-calendar-container {
	height: auto;
	bottom: 0px;
	width: 100%;
}

.events-calendar-header {
	height: 50px;
	position: relative;
}

.events-calendar-header h2,
.events-calendar-header h3 {
	float: left;
	text-shadow: none;
	margin-bottom: 0;
}

.events-calendar-header h2 {
	width: 60%;
}

.events-calendar-header h2 a,
.events-calendar-header h2 span {
	font-size: 18px;
	white-space: nowrap;
}

.events-calendar-header h3 {
	width: 40%;
	color: #666;
	font-size: 20px;
	text-align: right;
	padding-top: 7px;
	padding-right: 130px;
}

.events-calendar-header h3 span { color: #666; }

.events-calendar-header nav {
	position: absolute;
	right: 0;
	top: 5px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.events-calendar-header nav span {
	float: left;
	width: 30px;
	height: 30px;
	line-height: 30px;
	position: relative;
	color: transparent;
	cursor: pointer;
	background: #444;
	margin: 0 1px;
	font-size: 14px;
	border-radius: 0 3px 3px 0;
	box-shadow: none;
}

.events-calendar-header nav span:first-child {
	border-radius: 3px 0 0 3px;
}

.events-calendar-header nav span:hover {
	background: #222;
}

.events-calendar-header span i {
	color: #FFF;
	text-align: center;
	width: 100%;
}

.events-calendar-header nav span:last-child {
	margin-left: 20px;
	border-radius: 3px;
}

.fc-calendar {
	background: #F5F5F5;
	width: 100%;
	height: auto;
	margin-top: 10px;
	border-radius: 5px;
}

.fc-calendar .fc-head {
	background: #444;
	color: rgba(255,255,255,0.9);
	box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
	border-radius: 5px 5px 0 0;
	height: 40px;
	line-height: 40px;
	padding: 0 20px;
}

.fc-calendar .fc-head > div {
	font-weight: 300;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 2px;
	text-shadow: 0 1px 1px rgba(0,0,0,0.4);
}

.fc-calendar .fc-row > div > span.fc-date {
	position: absolute;
	color: #333;
	text-shadow: none;
	font-size: 28px;
	font-weight: 300;
	bottom: auto;
	right: auto;
	top: 10px;
	left: 8px;
	text-align: left;
	text-shadow: none;
}

.fc-calendar .fc-body {
	border: none;
	height: 568px;
	padding: 20px;
}

.fc-calendar .fc-row {
	box-shadow: inset 0 -1px 0 #E5E5E5;
	border: none;
}

.fc-calendar .fc-row:last-child {
	box-shadow: none !important;
}

.fc-calendar .fc-row:first-child > div:first-child {
	border-radius: 5px 0 0 0;
}

.fc-calendar .fc-row:first-child > div:last-child {
	border-radius: 0 5px 0 0;
}

.fc-calendar .fc-row:last-child > div:first-child {
	border-radius: 0 0 0 5px;
}

.fc-calendar .fc-row:last-child > div:last-child {
	border-radius: 0 0 5px 0;
}

.fc-calendar .fc-row > div {
	box-shadow: -1px 0 0 #E5E5E5;
	border: none;
	padding: 10px;
}

.fc-calendar .fc-row > div:first-child{
	box-shadow: none !important;
}

.fc-calendar .fc-row > div.fc-today {
	background: #1E73BE;
	box-shadow: inset 0 0 100px rgba(255,255,255,0.2);
}

.fc-calendar .fc-row > div.fc-today > span.fc-date {
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.fc-calendar .fc-row > div.fc-today:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.2;
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0.15)), to(rgba(0, 0, 0, 0.25))), -webkit-gradient(linear, left top, right bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(0.5, rgba(255, 255, 255, .15)), color-stop(0.501, rgba(255, 255, 255, 0)), color-stop(1, rgba(255, 255, 255, 0)));
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.25)), -moz-linear-gradient(left top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
	background: -o-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.25)), -o-llinear-gradient(left top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
	background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.25)), -ms-linear-gradient(left top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
	background: linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.25)), linear-gradient(left top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
}

.fc-calendar .fc-row > div > div {
	margin-top: 35px;
}

.fc-calendar .fc-row > div > div a,
.fc-calendar .fc-row > div > div span {
	position: relative;
	z-index: 1;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
	font-size: 12px;
	display: inline-block;
	padding: 3px 5px;
	border-radius: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	margin-bottom: 1px;
	background: #1E73BE;
	-webkit-transition: background-color .3s ease-in-out;
	-moz-transition: background-color .3s ease-in-out;
	-ms-transition: background-color .3s ease-in-out;
	-o-transition: background-color .3s ease-in-out;
	transition: background-color .3s ease-in-out;
}

.fc-calendar .fc-row > div > div a:hover { background: #444 !important; }

.fc-calendar .fc-row > div.fc-today > div a,
.fc-calendar .fc-row > div.fc-today > div span {
	color: #FFF;
	background: rgba(255,255,255,0.2);
}

.dark .events-calendar-header h2,
.dark .events-calendar-header h3 {
	text-shadow: 1px 1px 0 rgba(0,0,0,0.1);
}

.dark .events-calendar-header h2 { color: #FFF; }

.dark .events-calendar-header h2 a,
.dark .events-calendar-header h2 span { color: rgba(255,255,255,0.3); }

.dark .events-calendar-header h2 a { color: rgba(255,255,255,0.5); }

.dark .events-calendar-header h2 a:hover { color: rgba(255,255,255,0.9); }

.dark .events-calendar-header h3 {
	color: #CCC;
	color: rgba(255,255,255,0.6);
}

.dark .events-calendar-header h3 span { color: #CCC; }

.dark .events-calendar-header nav span {
	background: rgba(255,255,255,0.3);
	box-shadow: inset 0 1px rgba(255,255,255,0.2);
}

.dark .events-calendar-header nav span:hover { background: rgba(255,255,255,0.5); }

.dark .events-calendar-header span i { color: #FFF; }

.dark .fc-calendar { background: rgba(255,255,255,0.1); }

.dark .fc-calendar .fc-head {
	background: rgba(255,255,255,0.2);
	color: rgba(255,255,255,0.9);
	box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
}

.dark .fc-calendar .fc-head > div { text-shadow: 0 1px 1px rgba(0,0,0,0.4); }

.dark .fc-calendar .fc-row > div > span.fc-date {
	color: rgba(255,255,255,0.9);
	text-shadow: 0 1px 1px rgba(0,0,0,0.3);
}

.dark .fc-calendar .fc-row { box-shadow: inset 0 -1px 0 rgba(255,255,255,0.10); }

.dark .fc-calendar .fc-row > div { box-shadow: -1px 0 0 rgba(255, 255, 255, 0.10); }

.dark .fc-calendar .fc-row > div.fc-today {
	background: transparent;
	box-shadow: inset 0 0 100px rgba(255,255,255,0.2);
}

.dark .fc-calendar .fc-row > div > div a,
.dark .fc-calendar .fc-row > div > div span {
	color: rgba(255,255,255,0.7);
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
	background: rgba(255,255,255,0.1);
}

.dark .fc-calendar .fc-row > div > div a:hover { background: rgba(255,255,255,0.3) !important; }


@media screen and (max-width: 991px) {

	.events-calendar-header,
	.events-calendar-header nav,
	.events-calendar,
	.fc-calendar-container,
	.fc-calendar,
	.fc-calendar .fc-head,
	.fc-calendar .fc-row > div > span.fc-date {
		position: relative;
		top: auto;
		left: auto;
		bottom: auto;
		right: auto;
		height: auto;
		width: auto;
	}

	.fc-calendar .fc-body { height: auto; }

	.fc-calendar { margin: 20px 0; }

	.events-calendar-header h2,
	.events-calendar-header h3 {
		float: none;
		width: auto;
		text-align: left;
		padding: 0;
	}

	.fc-calendar .fc-row,
	.ie9 .fc-calendar .fc-row > div,
	.fc-calendar .fc-row > div {
		height: auto;
		width: 100%;
		border: none;
	}

	.fc-calendar .fc-row > div {
		float: none;
		min-height: 50px;
		box-shadow: inset 0 -1px #E5E5E5 !important;
		border-radius: 0px !important;
	}

	.fc-calendar .fc-row > div:first-child { box-shadow: inset 0 -1px #E5E5E5 !important; }

	.fc-calendar .fc-row > div:empty{
		min-height: 0;
		height: 0;
		box-shadow: none !important;
		padding: 0;
	}

	.fc-calendar .fc-row {
		box-shadow: none;
	}

	.fc-calendar .fc-head {
		display: none;
	}

	.fc-calendar .fc-row > div > div {
		margin-top: 0px;
		padding-left: 10px;
		max-width: 68%;
		display: inline-block;
	}

	.fc-calendar .fc-row > div.fc-today {
		background: #1E73BE;
	}

	.fc-calendar .fc-row > div.fc-today:after {
		display: none;
	}

	.fc-calendar .fc-row > div > span.fc-date {
		width: 30px;
		display: inline-block;
		text-align: right;
	}

	.fc-calendar .fc-row > div > span.fc-weekday {
		display: inline-block;
		width: 40px;
		color: #999;
		font-size: 10px;
		text-transform: uppercase;
	}


	.dark .fc-calendar .fc-row > div { box-shadow: inset 0 -1px rgba(255,255,255,0.2) !important; }

	.dark .fc-calendar .fc-row > div.fc-today { background: rgba(255, 255, 255, 0.2); }

	.dark .fc-calendar .fc-row > div > span.fc-weekday,
	.fc-calendar .fc-row > div.fc-today > span.fc-weekday {
		color: #fff;
		color: rgba(255,255,255,0.7);
	}

}